import * as React from 'react'
import { IDeposit } from './DepositPage'
import { Card, Col, Row } from 'antd'
import { getTransactionUrl as transactionUrlHelper } from '../../utils/transactionsHelper'
import { Link } from 'react-router-dom'
import dateFormatter from '../../utils/dateFormatter'
import { useEffect, useState } from 'react'
import RestService from '../../RestService'
import * as uuid from 'uuid'

interface IDepositDetailsProps {
  restService: RestService
  referenceId: string
  asset: string
}

const DetailRow = ({
  label,
  value
}: {
  label: string
  value: string | number | JSX.Element
}) => {
  return (
    <Row
      style={{
        padding: '2px 0',
        borderBottom: '1px solid #e8e8e8'
      }}
    >
      <Col span={8}>
        <span style={{ overflowWrap: 'break-word' }}>{label}</span>
      </Col>
      <Col span={16}>{value}</Col>
    </Row>
  )
}

const DepositDetails = (props: IDepositDetailsProps) => {
  const { referenceId, asset } = props
  const [getDeposit, setDeposit] = useState<IDeposit>(null)
  const [getTransactionUrl, setTransactionUrl] = useState(null)

  useEffect(() => {
    ;(async function () {
      const fetchDepositGetParams = new URLSearchParams({
        requestId: uuid.v4()
      })

      if (asset) {
        fetchDepositGetParams.append('asset', asset)
      }

      const { deposit } = await props.restService
        .route(`deposits/${referenceId}?${fetchDepositGetParams.toString()}`)
        .get()

      setDeposit(deposit)
      setTransactionUrl(transactionUrlHelper(deposit?.currency))
    })()
  }, [])

  if (!getDeposit) {
    return (
      <>
        {}
        <div>Deposit not found</div>
      </>
    )
  }

  return (
    <Card title="Deposit Details" style={{ background: 'inherit' }}>
      <DetailRow
        label="External Reference ID (wire, transfer or on-chain transaction)"
        value={
          getTransactionUrl ? (
            <a
              href={`${getTransactionUrl}/${referenceId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {referenceId}
            </a>
          ) : (
            referenceId
          )
        }
      />
      {getDeposit && (
        <>
          <DetailRow label="Amount" value={getDeposit.amount} />
          <DetailRow label="Currency" value={getDeposit.currency} />
          <DetailRow
            label="Deposit Movement ID"
            value={
              <Link
                to={`/${getDeposit.participant_code}/balances/${getDeposit.currency}/history/${getDeposit.account_id}/movements/${getDeposit.version}#${getDeposit.deposit_id}`}
              >
                {getDeposit.account_id}
              </Link>
            }
          />
          <DetailRow
            label="Participant Code"
            value={getDeposit.participant_code}
          />
          <DetailRow
            label="Submit Timestamp"
            value={dateFormatter(getDeposit.submit_timestamp)}
          />
          <DetailRow
            label="Settle Timestamp"
            value={dateFormatter(getDeposit.settle_timestamp)}
          />
          <DetailRow label="Run ID" value={getDeposit.version} />
        </>
      )}

      <DetailRow label="Settlement Agent Name" value="Zero Hash LLC" />
      <DetailRow
        label="Settlement Agent Contact"
        value="To ask questions or register complaints related to trading on Zero
            Hash, please call (855) 744-7333."
      />
      <DetailRow
        label="Settlement Agent Address"
        value="327 N. Aberdeen St., FL 1, Chicago, IL 60607"
      />
    </Card>
  )
}

export default DepositDetails
