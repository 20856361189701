import React, { useEffect, useState } from 'react'
import { Layout } from 'antd'
import {
  RouteComponentProps,
  BrowserRouter,
  Switch,
  Route,
  useLocation
} from 'react-router-dom'

import MasterPage from './components/MasterPage'
import { IAuthUser } from './auth/WithAuthentication'
import withAuthentication from './auth/WithAuthentication'
import RestService from './RestService'
import SideBar from './components/top-menu'
import VerifyEmail from './components/VerifyEmail'
import EmailVerified from './components/EmailVerified'
import TopNav from './top-nav'
import { FlagContext, IToggle } from './unleash/FlagContext'
import ZHSpin from './components/ZHSpin'
import EnvBar from './components/EnvBar'

const { Header, Content, Footer } = Layout

export interface IDefaultComponentProps extends RouteComponentProps<any> {
  asset?: string
  participantCode: string
  restService: RestService
}
interface IAppProps extends RouteComponentProps {
  authUser: IAuthUser
}

const AuthenticatedApp = ({ authUser, ...props }: IAppProps) => {
  const location = useLocation()
  const [toggles, setToggles] = useState<IToggle[]>([])
  const [isLoadingFlags, setIsLoadingFlags] = useState(true)

  const getFeatureFlags = async () => {
    setIsLoadingFlags(true)
    const queryParams = new URLSearchParams({
      appName: 'portal-app'
    })
    try {
      const response = await fetch(
        `${window._env_.REACT_APP_UNLEASH_EDGE_URL}?${queryParams}`,
        {
          headers: {
            Authorization: window._env_.REACT_APP_UNLEASH_APIKEY
          }
        }
      )
      const jsonResponse = await response.json()
      setToggles(jsonResponse.toggles)
    } catch (e) {
      console.warn('Failed to fetch Feature toggles', e)
    }
    setIsLoadingFlags(false)
  }

  useEffect(() => {
    getFeatureFlags()
  }, [])

  const maybeParticipantCode = location.pathname.split('/')[1]
  const maybeParticipant = authUser.participants.find(
    (p) => p.code === maybeParticipantCode
  )
  if (isLoadingFlags) return <ZHSpin />
  return (
    <FlagContext.Provider value={{ toggles, isLoadingFlags }}>
      <Layout style={{ minHeight: '100vh' }}>
        <Header style={{ padding: '0 20px' }}>
          <TopNav
            authUser={authUser}
            {...props}
            participant={maybeParticipant}
          />
        </Header>
        <div>
          <EnvBar env={window._env_.REACT_APP_SEED_ENV} />
        </div>
        <Layout>
          <Layout className="wavy-background">
            <SideBar user={authUser} />
            <Content
              className="transparent"
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                borderTop: '1px solid #00f197'
              }}
            >
              <MasterPage authUser={authUser} />
            </Content>
          </Layout>
        </Layout>
        <Footer
          style={{
            lineHeight: 0.8,
            backgroundColor: '#ebebeb',
            padding: '8px 16px'
          }}
        >
          <span className="footer-text transparent">
            Zero Hash Holdings Ltd., through its affiliates, provides trading,
            settlement, liquidity and other related services for digital assets.
            Zero Hash LLC ("Zero Hash"), Zero Hash Liquidity Services LLC
            ("ZHLS"), Zero Hash Australia Pty Ltd ("ZH AU"), Zero Hash Brazil
            Limitada ("ZH Brazil"), Zero Hash UK Limited ("ZH UK"), and Zero
            Hash Europe B.V. ("ZH EU") are wholly owned subsidiaries of Zero
            Hash Holdings Ltd. Zero Hash and ZHLS each hold BitLicenses with the
            New York Department of Financial Services and Money Services
            Business licenses with FinCEN. Zero Hash is also a licensed money
            transmitter in over forty U.S. states (a full list of which can be
            found{' '}
            <a
              href="https://zerohash.zendesk.com/hc/en-us/articles/360008819314-State-Money-Transmitter-Disclosures-"
              target="_blank"
            >
              here
            </a>
            ) and holds a Money Services Business license with FINTRAC in
            Canada.ZH AU is a licensed Digital Currency Exchange with AUSTRAC in
            Australia. A list of all legal and compliance-related policies and
            disclosures for Zero Hash Holdings Ltd. and its affiliates can be
            found{' '}
            <a
              href="https://zerohash.zendesk.com/hc/en-us/categories/360000821594-Legal-and-Compliance"
              target="_blank"
            >
              here
            </a>
            .
          </span>
        </Footer>
      </Layout>
    </FlagContext.Provider>
  )
}

const App = () => (
  <div>
    <BrowserRouter>
      <Switch>
        <Route path="/verify_email" component={VerifyEmail} />
        <Route path="/email_verified" component={EmailVerified} />
        <Route path="*" component={withAuthentication(AuthenticatedApp)} />
      </Switch>
    </BrowserRouter>
  </div>
)
export default App
