import * as React from 'react'

const envMap: any = {
  gat: { color: '#cccc00', text: 'Gating', height: 20 },
  dev: { color: '#33c133', text: 'Development', height: 20 },
  staging: { color: '#ffb050', text: 'Staging', height: 20 },
  cert: { color: '#e6da4a', text: 'Certification', height: 20 },
  ops: { color: '#8477ee', text: 'Ops Env', height: 20 },
  prod: { color: '#caad6b', text: 'Production', height: 0 }
}

type SeedEnvProps = {
  env: 'gat' | 'dev' | 'staging' | 'cert' | 'ops' | 'prod'
}

const EnvBar = ({ env }: SeedEnvProps) => {
  if (!envMap[env] || env === 'prod') {
    return null
  }

  const { height, color, text } = envMap[env]

  return (
    <div
      style={{
        height: height,
        backgroundColor: color,
        textAlign: 'center',
        width: '100%'
      }}
    >
      {text}
    </div>
  )
}
export default EnvBar
